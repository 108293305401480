import React, {useContext} from "react";
import style from "./modalContainer.module.scss";

//
// Add this at the base of your layout. Acts as the point where the modal will be generated
//
const ModalContainer = () => {
  return (
    <>
      <div className={style.modalContainerRoot}>
        <div id="modal-container-root"></div>
      </div>
    </>
  );
};

export default ModalContainer;
