import "./button-hero.scss";

function ButtonHero({
  link,
  target,
  rel,
  content,
  color,
  is3d = true,
  isPulsing = false,
  scale = 1,
  size = 1,
  outlineOnHover = false,
  width = "500px",
  glow = "glow-soft",
  onClick = ()=>{},
}) {
  // Glow can be hard, soft, or no-glow
  return (
    <div style={{width: `min(100%, ${width})`}}>
      <a href={link} target={target} rel={rel} onClick={onClick}>
        <div className={`button-hero-container button-${color} button-${size}`} style={{scale: scale}}>
          {is3d && <div className="button-hero-decoy"></div>}
          <div
            className={`button-hero-parent ${is3d ? "is3dButton" : ""} ${isPulsing ? "isPulsing" : ""} ${
              outlineOnHover ? "outline-on-hover" : ""
            }`}
            tabIndex={0}>
            <div className={`button-hero text-center ${glow}`}>{content}</div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default ButtonHero;
