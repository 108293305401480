import "./nav-menu-button.scss";

function NavMenuButton({ content, link, isActive, onClick }) {
  return (
    <div onClick={onClick} className={`nav-menu-button-container ${isActive ? "active" : ""}`} tabIndex={0}>
      {link ? (
          <a href={link}>
          <div className={`nav-menu-button`}>
            {content}
          </div>
        </a>
        // <HashLink to={link}>
        //   <div className={`nav-menu-button`}>{content}</div>
        // </HashLink>
      ) : (
        <>
          <div className={`nav-menu-button`}>{content}</div>
        </>
      )}
    </div>
  );
}

export default NavMenuButton;
