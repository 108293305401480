import {useEffect, useRef} from "react";
import {isMobileDevice, isPerformanceFasterThan} from "../../utils/utils";
const ioConfiguration = {
  /**
   * This rootMargin creates a horizontal line vertically centered
   * that will help trigger an intersection at that the very point.
   */
  rootMargin: "0% 0% 0% 0%",

  /**
   * This is the default so you could remove it.
   * I just wanted to leave it here to make it more explicit
   * as this threshold is the only one that works with the above
   * rootMargin
   */
  threshold: 0,
};
// Register IntersectionObserver
const revealerIo = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      if (entry.target.classList?.contains("reveal-on-scroll")) {
        entry.target.style.willChange = "opacity, transform";
      }
      // Adding a little delay so the browser gets to do the will-change optimizations
      // and so we catch the whole animation
      // setTimeout(() => {
      // Observation target is inside viewport
      entry.target.style["opacity"] = 1;
      entry.target.classList.remove("reveal-on-scroll");
      // }, 1);
    } else {
      // Also add the class back if you want the element to reveal
      // itself next times it comes into viewport

      entry.target.classList.add("revealed-on-scroll");
      if (entry.target.style.willChange !== "auto") {
        entry.target.style.willChange = "auto";
      }
    }
  });
}, ioConfiguration);

const autoplayIo = new IntersectionObserver(
  (entries) => {
    // console.log("autoplayIo entries", entries);
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        if (entry.target.tagName === "VIDEO") {
          entry.target.play();
        } else {
          const src = entry.target.src;
          entry.target.src =
            src.substr(0, src.search("autoplay=")) + "autoplay=1" + src.substr(src.search("autoplay=") + 10);
        }
      } else {
        if (entry.target.tagName === "VIDEO") {
        } else {
          const src = entry.target.src;
          entry.target.src =
            src.substr(0, src.search("autoplay=")) + "autoplay=0" + src.substr(src.search("autoplay=") + 10);
        }
      }
    });
  },
  {...ioConfiguration, rootMargin: "-40% 0% -35% 0%"}
);

function AnimationRevealer() {
  const performanceObserver = useRef();

  useEffect(() => {
    async function conditionallyRenderAnimations() {
      try {
        const renderAnimations = () => {
          setTimeout(() => {
            const animatedElements = [...document.getElementsByClassName("reveal-on-scroll")];
            animatedElements.forEach((el) => {
              el.style["opacity"] = 0;
              revealerIo.observe(el);
            });
          }, 500);
        };

        const listenForAutoplay = () => {
          const autoplayElements = [...document.getElementsByClassName("autoplay-on-scroll")];
          autoplayElements.forEach((el) => {
            autoplayIo.observe(el);
          });
        };
        
        listenForAutoplay();
        if ((await isPerformanceFasterThan(150, performanceObserver)) && !isMobileDevice()) {
          console.log("isPerformanceFasterThan(150) - animations on", true);
          renderAnimations();
          performanceObserver.current?.disconnect();
        } else {
          console.log("isPerformanceFasterThan(150) - animations off", true);
          // Remove animations
          console.log("removing animations");
          const animatedElements = [...document.getElementsByClassName("animate__animated")];
          animatedElements.forEach((el) => {
            el.classList.remove("animate__animated");
          });
        }
      } catch (err) {
        console.log(err);
      }
    }

    conditionallyRenderAnimations();

    return () => {
      performanceObserver.current?.disconnect();

      const animatedElements = [
        ...document.getElementsByClassName("reveal-on-scroll"),
        ...document.getElementsByClassName("revealed-on-scroll"),
      ];
      animatedElements.forEach((el) => {
        el.style["opacity"] = 0;
        revealerIo.unobserve(el);
      });

      const autoplayElements = [...document.getElementsByClassName("autoplay-on-scroll")];
      autoplayElements.forEach((el) => {
        autoplayIo.unobserve(el);
      });

      revealerIo.disconnect();
      autoplayIo.disconnect();
    };
  }, []);

  return <div></div>;
}

export default AnimationRevealer;
